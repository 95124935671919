import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Menu } from "antd";
import KeywordTitle from "./KeywordTitle";
import PxButton from "../../../components/Buttons/PxButton";
import "antd/dist/antd.css";
import { Chip } from "@material-ui/core";
import PxChip from "../../../components/Chips/PxChips";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { v4 as uuidv4 } from "uuid";
import { FormContext } from "../../../components/Providers/FormProvider/FormProvider";

export interface KeywordType {
  key?: string;
  title: string;
  icon?: ReactNode;
  type?: "group";
  id?: number;
  children?: KeywordType[];
}

interface Props {
  //   colunmId: string; // droppable container column Id
  //   selectContent?: boolean; // 단수나 복수 콘텐츠 drag & drop 인지 구분
  //   formId?: string; // 단수나 복수 콘텐츠 정렬 시 initialData formData의 id값 필요
  //   initialData: KeywordType[] | undefined; // draggable items data
  //   setInitialData: React.Dispatch<React.SetStateAction<FormData>>; // draggable items setState
}

const KeywordTree: React.FC<Props> = (props) => {
  const { setPickKeywordIds } = useContext(FormContext);
  const keywords = useSelector((state: AppState) => state.forms.keywords);
  const [selectedKey, setSelectedKey] = useState<string | null>();
  const [selectedKeyword, setSelectedKeyword] = useState<string>("");
  const [pickKeywords, setPickKeywords] = useState<Set<string>>(new Set());
  // const [pickKeywordIds, setPickKeywordIds] = useState<Set<number>>(new Set());
  const [subEventKeywords, setSubEventKeywords] = useState<KeywordType[]>([]);

  useEffect(() => {
    if (!keywords) return;
    setSubEventKeywords(initializeKey(keywords));
  }, [keywords]);

  useEffect(() => {
    const keywordRoot: KeywordType[] | null = findPathToRoot(
      subEventKeywords,
      selectedKeyword
    );
    if (keywordRoot) {
      setPickKeywords((prev) => {
        const path = keywordRoot?.map((item) => item.title).join(" > ");
        return path !== undefined ? new Set(prev).add(path) : prev;
      });
      setPickKeywordIds((prev) => {
        const id = keywordRoot?.[keywordRoot.length - 1]!.id;
        return id !== undefined ? new Set(prev).add(id) : prev;
      });
    }
  }, [selectedKeyword, setPickKeywords, subEventKeywords]);

  const initializeKey = (keywords: KeywordType[]): KeywordType[] => {
    return keywords.map((parent) => {
      if (!parent.key) {
        parent.key = uuidv4();
      }
      if (parent.children) {
        initializeKey(parent.children);
      }
      return parent;
    });
  };

  const handleClick = (e: any) => {
    setSelectedKey(e.key);
  };

  const deleteMyKeyword = (keyword: string) => {
    setPickKeywords((prev) => {
      const newSet = new Set(prev);
      newSet.delete(keyword);
      return newSet;
    });
  };

  const findPathToRoot = (
    data: KeywordType[],
    targetKey: string,
    path: KeywordType[] = []
  ): KeywordType[] | null => {
    for (const item of data) {
      if (item.key === targetKey) {
        return [...path, item];
      }
      if (item.children) {
        const result = findPathToRoot(item.children, targetKey, [
          ...path,
          item,
        ]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const generateKeword = (targetKey: string): string | null => {
    const path = findPathToRoot(subEventKeywords, targetKey);
    if (path) {
      return path.map((item) => item.title).join(" > ");
    }
    return null;
  };

  const renderMenuItems = (menuItems: KeywordType[]) => {
    return menuItems.map((item) => {
      if (item.children) {
        return (
          <Menu.SubMenu
            key={item.key}
            title={
              <KeywordTitle
                item={item}
                setSelectedKeyword={setSelectedKeyword}
              />
            }
            style={{ minWidth: "280px" }}
          >
            {renderMenuItems(item.children)}
          </Menu.SubMenu>
        );
      }

      return (
        <Menu.Item
          key={item.key}
          style={{
            display: "flex",
            justifyContent: "space-between",
            minWidth: "280px",
          }}
        >
          <KeywordTitle item={item} setSelectedKeyword={setSelectedKeyword} />
        </Menu.Item>
      );
    });
  };

  return (
    <>
      <Menu
        onClick={handleClick}
        mode="vertical"
        style={{ minWidth: 290, maxWidth: 330, padding: 10 }}
        triggerSubMenuAction="click"
      >
        {subEventKeywords.length > 0 && renderMenuItems(subEventKeywords)}
      </Menu>
      <div>
        {[...pickKeywords].map((keyword, index) => (
          <Chip
            key={index}
            variant="outlined"
            label={keyword}
            onDelete={() => deleteMyKeyword(keyword)}
          />
        ))}
      </div>
    </>
  );
};

export default KeywordTree;
