import React, { SetStateAction } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { FormData } from "../../../types/models/Form";
import KeywordSelectBox from "./KeywordSelectBox";
import KeywordTree from "./KeywordTree";

interface Props {
  id: string;
  keywordData: string[];
  preview: boolean;
}

const KeywordView: React.FC<Props> = (props) => {
  const { id, keywordData, preview } = props;
  const form = useSelector((state: AppState) => state.forms);

  // id: string;
  // keywordData: string[];
  // preview: boolean;
  // keywordOptions: string[];
  // setFormData: React.Dispatch<SetStateAction<FormData>>;

  return (
    <>
      {form.type === "SP" && <KeywordTree />}
      {form.type === "BYP" && (
        <KeywordSelectBox id={id} keywordData={keywordData} preview={preview} />
      )}
    </>
  );
};

export default KeywordView;
