/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, ReactNode, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import uuid from "uuid";

// types
import {
  FormData,
  AutonoForm,
  ProductProperty,
} from "../../../types/models/Form";
import {
  ApplicationFormAnswers,
  Application,
} from "../../../types/models/Application";
import { AppState } from "../../../store";
import { SubEventInfo } from "../../../types/models/SubEvent";

// actions
import { removeForm, getForm } from "../../../actions/forms";
import { removeSubEvent, getSubEvent } from "../../../actions/subEvents";
import {
  createApplication,
  noneLoginCreateApplication,
} from "../../../actions/applications";
import BusinessCard from "../../../types/models/BusinessCard";
import {
  setLoadingAction,
  removeLoadingAction,
} from "../../../actions/loading";
import { getAuthToken, getUser } from "../../../actions/users";
import { newAlert } from "../../../actions/alerts";

interface FormContextType {
  subEvent?: SubEventInfo;
  formData: FormData; // 본문 양식
  adFormData?: FormData; // 추가정보 양식
  businessCardId: number; // 명함 셀렉트박스 index
  businessCardList: BusinessCard[]; // 전체 명함 data 리스트 (직접입력 정보 포함)
  resultInfo: ResultInfo;
  pickKeywordIds: Set<number>;
  checkOnlyEng: (value: string) => boolean;
  checkOnlyEngReturnString: (value: string) => string;
  submitForm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  setAdFormData: React.Dispatch<React.SetStateAction<FormData>>;
  setBusinessCardId: React.Dispatch<React.SetStateAction<number>>;
  setBusinessCardList: React.Dispatch<React.SetStateAction<BusinessCard[]>>;
  addAdForm: () => void;
  deleteAdForm: (index: number) => void;
  setPickKeywordIds: React.Dispatch<React.SetStateAction<Set<number>>>;
}

export const FormContext = createContext<FormContextType>(null!);

interface FormProviderProps {
  children: ReactNode;
}
interface ResultInfo {
  email: string;
  temporaryPW: string;
  temporaryCode: string;
}

// 추가양식 추가하기 시 기본으로 사용 될 폼
let initialAdFormData: FormData = {
  formData: {},
  formIds: [],
  adFormIds: [],
};

const FormProvider = ({ children }: FormProviderProps) => {
  const history = useHistory();
  const subEventId = history.location.pathname.split("/")[3]; // subEvent Id
  const pattern_eng = /[^~!@#$/₩`\\:?'";.,₩\#$%<>^&*\()\-|.{}=+_\’\w\s]+/g;

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state: AppState) => state.users);
  const { subEvent } = useSelector((state: AppState) => state.subEvents);
  const { content, additional } = useSelector((state: AppState) => state.forms);

  const [attendeeType, setAttendeeType] = useState<
    "buyer" | "seller" | "visitor" | "booth"
  >();
  const [formId, setFormId] = useState<number>(); // 주최사가 작성한 자율양식의 form id값, state에서 가져오려고 했으나 setAlert 시 form redux state가 초기화 되는 버그로 인해 임시방편으로 둠
  const [businessCardId, setBusinessCardId] = useState<number>(0); // 명함 select box state
  const [resultInfo, setResultInfo] = useState<ResultInfo>({
    email: "",
    temporaryPW: "",
    temporaryCode: "",
  }); // 참가 신청후 결과페이지 보여질 내용

  // 신청서 api request form
  const [application, setApplication] = useState<Application>({
    subEventId: subEvent?.id as string,
    applicationFormAnswers: [],
    businessCards: [],
    applicants: [],
    method: "pre",
  });

  const [pickKeywordIds, setPickKeywordIds] = useState<Set<number>>(new Set());

  const [formData, setFormData] = useState<FormData>(null!); // 신청양식 data (string -> JSON as FormData)
  const [adFormData, setAdFormData] = useState<FormData>(null!); // 추가정보 양식 data (string -> JSON as FormData)
  const [businessCardList, setBusinessCardList] = useState<BusinessCard[]>([
    {
      name: "",
      email: "",
      company: "",
      position: "",
      countryNumber: "82",
      phoneNumber: "",
    },
  ]); // 명함정보 data (신청서에서 명함을 선택하거나 직접 작성한 data를 가짐, 마지막 index는 유저가 직접 작성한 명함정보)

  // subEvent 정보 불러오기
  useEffect(() => {
    dispatch(getSubEvent(subEventId));
    // 컴포넌트 unmount 시 redux form state와 subEvent state 지우기
    return () => {
      dispatch(removeForm());
      dispatch(removeSubEvent());
      initialAdFormData = {
        formData: {},
        formIds: [],
        adFormIds: [],
      };
    };
  }, []);

  // form redux state의 content 값이 들어오면 해당 string 값을 JSON으로 바꿔줌 (신청양식 ui를 그리기 위함)
  useEffect(() => {
    if (content !== "" && content !== undefined) {
      const formStringToJson: FormData = JSON.parse(content as string);
      // 소속명 지우기
      // const filterObject = (
      //   obj: FormData,
      //   filter: keyof AutonoForm
      // ): {
      //   [key: string]: AutonoForm;
      // } =>
      //   Object.keys(obj.formData).reduce((acc, val) => {
      //     let newAcc: {
      //       [key: string]: AutonoForm;
      //     };
      //     if (
      //       obj.formData[val][filter] === "소속명" ||
      //       obj.formData[val][filter] === "Organization name"
      //     ) {
      //       const id = obj.formData[val].id;
      //       const object = obj.formIds.filter((val) => {
      //         return val != id;
      //       });
      //       formStringToJson.formIds = object;
      //       newAcc = acc;
      //     } else {
      //       newAcc = { ...acc, [val]: obj.formData[val] };
      //     }
      //     return newAcc;
      //   }, {});
      // formStringToJson.formData = filterObject(formStringToJson, "title");
      setFormData(formStringToJson);
    }
  }, [content]);

  useEffect(() => {
    console.log(`pickKeywordIds : `, pickKeywordIds);
  }, [pickKeywordIds]);

  // form redux state의 content 값이 들어오면 해당 string 값을 JSON으로 바꿔줌 (추가양식 ui를 그리기 위함)
  useEffect(() => {
    if (additional !== "" && additional !== undefined && additional !== null) {
      const formStringToJson: FormData = JSON.parse(additional);
      // 처음 불러온 추가양식을 저장해 놓음
      initialAdFormData.formData = { ...formStringToJson.formData };
      initialAdFormData.formIds = [...formStringToJson.formIds];

      const adFormIds: string[] = [];
      formStringToJson.formIds.forEach((formId) => {
        adFormIds.push(formId);
      });
      formStringToJson.adFormIds = [[...adFormIds]];
      setAdFormData(formStringToJson);
    }
  }, [additional]);

  // 새로운 form을 만들고 기존 formData에 추가
  const addAdForm = () => {
    if (initialAdFormData.formIds.length !== 0) {
      const newAdFormIds: string[] = [];
      const newFormIds: string[] = [];
      initialAdFormData.formIds.forEach((formId) => {
        const uid = "form-" + uuid.v4();
        newAdFormIds.push(uid);
        newFormIds.push(uid);
        Object.assign(initialAdFormData.formData, {
          [uid]: { ...initialAdFormData.formData[formId] },
        });
        initialAdFormData.formData[uid].id = uid;
        initialAdFormData.formData[uid].content = "";
        initialAdFormData.formData[uid].radioContent = undefined;
        initialAdFormData.formData[uid].checkBoxContent = undefined;
        initialAdFormData.formData[uid].fileContent = undefined;
        initialAdFormData.formData[uid].additionOrder =
          adFormData.adFormIds?.length;

        delete initialAdFormData.formData[formId];
      });
      initialAdFormData.adFormIds = [[...newAdFormIds]];
      initialAdFormData.formIds = [...newFormIds];

      setAdFormData({
        formData: { ...adFormData.formData, ...initialAdFormData.formData },
        formIds: [...adFormData.formIds, ...initialAdFormData.formIds],
        adFormIds: [...adFormData.adFormIds!, ...initialAdFormData.adFormIds!],
      });
    }
  };

  const deleteAdForm = (index: number) => {
    if (adFormData.formIds.length !== 0) {
      adFormData.adFormIds![index].forEach((formId) => {
        delete adFormData.formData[formId];
        const index = adFormData.formIds.indexOf(formId);
        if (index >= 0) {
          adFormData.formIds.splice(index, 1);
        }
      });
      adFormData.adFormIds?.splice(index, 1);
      setAdFormData({ ...adFormData });
    }
  };
  const checkOnlyEng = (value: string): boolean => {
    if (
      value != "" &&
      value.match(pattern_eng) &&
      subEvent?.counselFormOnlyEng == "Y"
    ) {
      dispatch(newAlert("영어로 입력해주세요", "warning"));
      return true;
    }
    return false;
  };

  const checkOnlyEngReturnString = (value: string): string => {
    // console.log("object :>> ", RegExp(pattern_eng, value));
    if (
      value != "" &&
      value.match(pattern_eng) &&
      subEvent?.counselFormOnlyEng == "Y"
    ) {
      dispatch(newAlert("영어로 입력해주세요", "warning"));
      return value.replace(pattern_eng, "");
    }
    return value;
  };

  useEffect(() => {
    // redux subEvent 값이 있으면 form을 불러오는 action dispatch
    if (subEvent && subEvent?.id !== "") {
      setApplication({
        ...application,
        subEventId: subEvent?.id!,
        type: attendeeType!,
      });
      const formId = getSpecificForm();
      setFormId(formId);
      formId !== undefined &&
        formId !== 0 &&
        dispatch(getForm(formId.toString(), subEvent.id as string));
    }
  }, [subEvent]);

  // 수정된 formData를 string 형태로 바꿔서 submit
  const onSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    // 참가신청 로딩 start
    dispatch(setLoadingAction());

    const data: ApplicationFormAnswers[] = [];
    const checkBoxData: ApplicationFormAnswers[] = [];
    // 작성 된 자율양식 데이터를 서버로 보내기전 알맞은 형태로 가공
    formData.formIds.forEach((formUuid) => {
      const form: ApplicationFormAnswers = {
        title: formData.formData[formUuid].title ?? "",
        type: formData.formData[formUuid].type ?? "",
        uuid: formUuid,
        formId: formId!,
        repeatInfo: "N",
      };

      switch (formData.formData[formUuid].type) {
        case "category":
          form["content"] =
            formData.formData[formUuid].categoryContent?.content;
          form["answerUuid"] = formData.formData[formUuid].categoryContent?.id;
          data.push(form);
          break;
        case "keyword":
          form["content"] =
            formData.formData[formUuid].keywordContent?.toString();
          data.push(form);
          break;
        case "single":
          form["content"] = formData.formData[formUuid].radioContent?.content;
          form["answerUuid"] = formData.formData[formUuid].radioContent?.id;
          data.push(form);
          break;
        case "many":
          formData.formData[formUuid].checkBoxContent?.forEach(
            ({ id, content }, index) => {
              const checkBoxForm: ApplicationFormAnswers = {
                title: formData.formData[formUuid].title ?? "",
                type: formData.formData[formUuid].type ?? "",
                uuid: formUuid,
                formId: formId!,
                repeatInfo: "N",
              };
              checkBoxForm["content"] = content;
              checkBoxForm["answerUuid"] = id;
              checkBoxData.push(checkBoxForm);
            }
          );
          break;
        case "file":
          form["content"] = formData.formData[formUuid].fileContent?.fileId;
          data.push(form);
          break;
        case "product":
          // form["content"] = JSON.stringify(
          //   formData.formData[formUuid].content as ProductProperty
          // );
          const productInfo = formData.formData[formUuid]
            .content as ProductProperty;
          form["productName"] = productInfo.title;
          form["productExplain"] = productInfo.explain;
          form["productImg"] = productInfo.productImg.fileId;
          form["productLink"] = productInfo.link;

          form["answerUuid"] = formData.formData[formUuid].id;
          data.push(form);
          break;
        default:
          form["content"] = formData.formData[formUuid].content as string;
          data.push(form);
          break;
      }
    });

    const answerData = data.concat(checkBoxData);

    const adData: ApplicationFormAnswers[] = [];
    const adCheckBoxData: ApplicationFormAnswers[] = [];
    // 작성 된 반복정보 양식 데이터를 서버로 보내기전 알맞은 형태로 가공
    if (adFormData !== null) {
      adFormData.adFormIds?.forEach((adFormIds) => {
        adFormIds.forEach((formUuid, index) => {
          const form: ApplicationFormAnswers = {
            title: adFormData.formData[formUuid].title ?? "",
            type: adFormData.formData[formUuid].type ?? "",
            uuid: adFormData.adFormIds![0][index],
            formId: formId!,
            repeatInfo: "Y",
            additionOrder: adFormData.formData[formUuid].additionOrder,
          };
          switch (adFormData.formData[formUuid].type) {
            case "single":
              form["content"] =
                adFormData.formData[formUuid].radioContent?.content;
              form["answerUuid"] =
                adFormData.formData[formUuid].radioContent?.id;
              adData.push(form);
              break;
            case "many":
              adFormData.formData[formUuid].checkBoxContent?.forEach(
                ({ id, content }) => {
                  const checkBoxForm: ApplicationFormAnswers = {
                    title: adFormData.formData[formUuid].title ?? "",
                    type: adFormData.formData[formUuid].type ?? "",
                    uuid: adFormData.adFormIds![0][index],
                    formId: formId!,
                    repeatInfo: "Y",
                    additionOrder: adFormData.formData[formUuid].additionOrder,
                  };
                  checkBoxForm["content"] = content;
                  checkBoxForm["answerUuid"] = id;
                  adCheckBoxData.push(checkBoxForm);
                }
              );
              break;
            case "file":
              form["content"] =
                adFormData.formData[formUuid].fileContent?.fileId;
              adData.push(form);
              break;
            case "product":
              // form["content"] = JSON.stringify(
              //   adFormData.formData[formUuid].content as ProductProperty
              // );

              const productInfo = adFormData.formData[formUuid]
                .content as ProductProperty;
              form["productName"] = productInfo.title;
              form["productExplain"] = productInfo.explain;
              form["productImg"] = productInfo.productImg.fileId;
              form["productLink"] = productInfo.link;

              form["answerUuid"] = adFormData.formData[formUuid].id;
              adData.push(form);
              break;
            default:
              form["content"] = adFormData.formData[formUuid].content as string;
              adData.push(form);
              break;
          }
        });
      });
    }

    const adAnswerData = adData.concat(adCheckBoxData);

    setApplication((prevState) => {
      prevState.applicationFormAnswers = [...answerData, ...adAnswerData];
      prevState.businessCards = [businessCardList[businessCardId]];
      if (prevState.type === "buyer" || prevState.type === "seller") {
        prevState.keywords = Array.from(pickKeywordIds);
      }
      return { ...prevState };
    });
  };

  // 참가신청 완료, application의 데이터 값에 따라 api call
  useEffect(() => {
    if (
      application.type !== undefined &&
      application.subEventId !== undefined &&
      application.businessCards.length !== 0
      // && application.applicationFormAnswers.length !== 0
    ) {
      createApplicationApiCall(application);
    }
  }, [application]);

  const createApplicationApiCall = async (application: Application) => {
    const result: any = (await isAuthenticated)
      ? await dispatch(createApplication(application))
      : await dispatch(noneLoginCreateApplication(application));
    if (result) {
      result.temporaryPW &&
        (await dispatch(
          getAuthToken({ email: result.email, password: result.temporaryPW })
        ));
      setResultInfo({
        email: result.email,
        temporaryPW: result.temporaryPW,
        temporaryCode: result.temporaryCode,
      });
      localStorage.userId && (await dispatch(getUser(localStorage.userId)));
      history.replace(`/form/subEvent/${subEventId}/result`);
    }
    // 참가신청 로딩 end
    dispatch(removeLoadingAction());
  };

  // url로 어떤 폼을 불러올지 formId 값 return
  const getSpecificForm = () => {
    const formName = history.location.pathname.split("/")[4];
    switch (formName) {
      case "sellerPartiForm":
        setAttendeeType("seller");
        return subEvent?.sellerPartiForm;
      case "buyerPartiForm":
        setAttendeeType("buyer");
        return subEvent?.buyerPartiForm;
      case "boothPartiForm":
        setAttendeeType("booth");
        return subEvent?.boothPartiForm;
      case "visitorPartiForm":
        setAttendeeType("visitor");
        return subEvent?.visitorPartiForm;
    }
  };

  return (
    <FormContext.Provider
      value={{
        subEvent: subEvent,
        formData: formData,
        adFormData: adFormData,
        businessCardId: businessCardId,
        businessCardList: businessCardList,
        resultInfo: resultInfo,
        pickKeywordIds: pickKeywordIds,
        checkOnlyEng: checkOnlyEng,
        checkOnlyEngReturnString: checkOnlyEngReturnString,
        submitForm: onSubmit,
        setFormData: setFormData,
        setAdFormData: setAdFormData,
        setBusinessCardId: setBusinessCardId,
        setBusinessCardList: setBusinessCardList,
        addAdForm: addAdForm,
        deleteAdForm: deleteAdForm,
        setPickKeywordIds: setPickKeywordIds,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider;
