import { Chip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PxGridItem from "../../../components/Grid/PxGridItem";
import PxOutlinedTxtField from "../../../components/Inputs/PxOutlinedTxtField";
import { FormContext } from "../../../components/Providers/FormProvider/FormProvider";
import { AppState } from "../../../store";
import { FormData } from "../../../types/models/Form";
import { getRequestAndReturn } from "../../../utils/utils";

interface KeywordRes {
  keywords: KeywordItemRes[];
}

interface KeywordItemRes {
  id: number;
  title: string;
}

interface Props {
  id: string;
  keywordData: string[];
  preview: boolean;
}

const KeywordSelectBox: React.FC<Props> = (props) => {
  const { keywordData, preview, id } = props;
  const { setFormData, setPickKeywordIds } = useContext(FormContext);
  const dispatch = useDispatch();
  const [t] = useTranslation("lang", { useSuspense: false });
  const subEvent = useSelector((state: AppState) => state.subEvents.subEvent);
  const [keywordList, setKeywordList] = useState<KeywordItemRes[]>([]);

  useEffect(() => {
    if (subEvent?.id == null) return;
    getSellerKeywords(subEvent.id);
  }, [subEvent]);

  const getSellerKeywords = async (subEventId: string) => {
    const res: any = await dispatch(
      getRequestAndReturn("/api/applicationKeyword", {
        subEventId: subEventId,
      })
    );
    const keywords: KeywordItemRes[] = (res as KeywordRes).keywords;
    setKeywordList(keywords);
  };

  return (
    <PxGridItem>
      <Autocomplete<KeywordItemRes, true, false, false>
        multiple
        options={keywordList}
        disabled={preview}
        id="keyword-list"
        freeSolo={false}
        disableCloseOnSelect={true}
        getOptionLabel={(option) => option.title}
        onChange={(event, value) => {
          setPickKeywordIds(new Set(value.map((item) => item.id)));
        }}
        renderTags={(value: KeywordItemRes[], getTagProps) => {
          return value.map((option: KeywordItemRes, index: number) => (
            <Chip
              key={index}
              variant="outlined"
              label={option.title}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderInput={(params) => (
          <PxOutlinedTxtField
            {...params}
            inputProps={{ ...params.inputProps, style: { padding: 0 } }}
            fullWidth
            disabled={preview}
            placeholder={t("form.pleaseSelectKeywords")}
            fontsize={14}
          />
        )}
      />
    </PxGridItem>
  );
};

export default KeywordSelectBox;
