import { AppActions } from "../store";
import { Form } from "../types/models/Form";
import { getRequest } from "../utils/utils";
import { Dispatch } from "redux";

export const getFormAction = (form: Form): AppActions => ({
  type: "GET_FORM",
  forms: form,
});

export const removeFormAction = (): AppActions => ({
  type: "REMOVE_FORM",
});

export const setFormContentAction = (content: string): AppActions => ({
  type: "SET_FORM_CONTENT",
  content: content,
});

export const getForm = (formId: string,subEventId?: string) => {
  return getRequest(null, "/api/forms/detail", getFormAction, {
    id: formId,
    subEventId
  });
};

export const removeForm = () => (dispatch: Dispatch<AppActions>) => {
  dispatch(removeFormAction());
};

export const setFormContent = (content: string) => (
  dispatch: Dispatch<AppActions>
) => {
  dispatch(setFormContentAction(content));
};
