import React, { useEffect } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { setRoutePath } from "../../actions/routePath";
import { useTranslation } from "react-i18next";

export interface PrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n } = useTranslation("lang", { useSuspense: false });

  const languageChange = async () => {
    const lang = await new URLSearchParams(location.search).get("lang");
    if (lang) {
      if (lang === "ko") {
        i18n.changeLanguage("ko");
        localStorage.setItem("language", "ko");
      } else {
        i18n.changeLanguage("en");
        localStorage.setItem("language", "en");
      }
    }
  };

  useEffect(() => {
    dispatch(setRoutePath({ previousPath: location.pathname }));
    languageChange();
  }, [location, dispatch]);

  let redirectPath = "/auth/login";

  if (props.isAuthenticated) {
    redirectPath = props.path;
  }

  if (props.isAuthenticated) {
    return <Route {...props} />;
  } else {
    return (
      <Route
        {...props}
        component={() => <Redirect to={{ pathname: redirectPath }} />}
        render={undefined}
      />
    );
  }
};

export default PrivateRoute;
