import Item from "antd/lib/list/Item";
import React, { Dispatch, SetStateAction } from "react";
import PxButton from "../../../components/Buttons/PxButton";
import ButtonTypo from "../../../components/Typhography/ButtonTypo";
import { KeywordType } from "./KeywordTree";

interface Props {
  item: KeywordType;
  setSelectedKeyword: React.Dispatch<SetStateAction<string>>;
}

const KeywordTitle: React.FC<Props> = (props) => {
  const { item, setSelectedKeyword } = props;

  const pickKeywordHandle = () => {
    console.log(`item key : `, item.key);
    if (!item.key) return;
    setSelectedKeyword(item.key);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <span>{item.title}</span>
      {item.children == undefined && (
        <PxButton backgroundcolor="purple" onClick={pickKeywordHandle}>
          <ButtonTypo>선택</ButtonTypo>
        </PxButton>
      )}
    </div>
  );
};

export default KeywordTitle;
